<template>
  <div>
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline"
      style="padding: 20px 20px 0"
    >
      <el-form-item label="上架人ucid">
        <el-input v-model="queryForm.publish_ucid" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="上架人手机号">
        <el-input v-model="queryForm.publish_mobile" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="上架人邮箱">
        <el-input v-model="queryForm.publish_email" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="nft集合名称">
        <el-input v-model="queryForm.name" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="上架开始时间">
        <el-date-picker
          type="date"
          placeholder="请选择"
          v-model="queryForm.created_start"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
        />
      </el-form-item>
      <el-form-item label="上架结束时间">
        <el-date-picker
          type="date"
          placeholder="请选择"
          v-model="queryForm.created_end"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
        />
      </el-form-item>
      <el-form-item label="法币最低价">
        <el-input-number
          :controls="false"
          :min="0"
          class="number-input"
          v-model="queryForm.fiat_min_price"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item label="法币最高价">
        <el-input-number
          :controls="false"
          :min="0"
          class="number-input"
          v-model="queryForm.fiat_max_price"
          placeholder="请输入"
        />
      </el-form-item>
      <!-- //  TODO 隐藏部分 -->
      <!-- <el-form-item label="bsv最低价">
        <el-input-number
          :controls="false"
          :min="0"
          class="number-input"
          v-model="queryForm.bsv_min_price"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item label="bsv最高价">
        <el-input-number
          :controls="false"
          :min="0"
          class="number-input"
          v-model="queryForm.bsv_max_price"
          placeholder="请输入"
        />
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <div style="padding-bottom: 20px;">
        <el-button
          :disabled="selectOrders.length === 0"
          type="danger"
          size="default"
          @click="handleOffShelf(selectOrders)"
        >
          批量强制下架
        </el-button>
      </div>
      <el-table
        :data="data"
        border
        stripe
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          v-for="col in columns"
          :prop="col.id"
          :key="col.id"
          :label="col.label"
          :sortable="col.sortable"
          :formatter="col.formatter"
        />
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleGoUser(scope.row)">
              用户统计
            </el-button>
            <el-button size="mini" type="primary" @click="handleGoNFT(scope.row)">
              集合详情
            </el-button>
            <el-button size="mini" type="danger" @click="handleOffShelf([scope.row])">
              强制下架
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getOnshelfOrders, offShelfOrders } from "@/api/nft.js";
import dayjs from "dayjs";
import { StartTime, EndTime } from "@/utils/helper.js";

const tableSortOrder2SortType = (val) => {
  if (val === "descending") {
    return "desc";
  }
  if (val === "ascending") {
    return "asc";
  }
  return "";
};

export default {
  data() {
    return {
      queryForm: {},
      page: 1,
      pageSize: 20,
      sortParams: {
        sort_name: "created_time",
        sort_type: "desc",
      },
      total: 0,
      selectOrders: [],
      data: [],
      columns: [
        {
          id: "id",
          label: "订单编号",
        },
        {
          id: "name",
          label: "NFT集合名称",
        },
        {
          id: "number",
          label: "NFT编号",
        },
        {
          id: "created_time",
          label: "上架时间",
          sortable: "custom",
          formatter: (row) => dayjs(+row.created_time * 1000).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          id: "publish_ucid",
          label: "上架人ucid",
        },
        {
          id: "publish_user_name",
          label: "上架人昵称",
        },
        {
          id: "fiat_amount",
          label: "法币价格",
          sortable: "custom",
        },
        //  TODO 隐藏部分
        // {
        //   id: "bsv_amount",
        //   label: "BSV价格",
        // },
      ],
    };
  },
  computed: {
    queryParams() {
      let created_start = undefined;
      let created_end = undefined;
      if (this.queryForm.created_start) {
        created_start = StartTime(this.queryForm.created_start);
      }
      if (this.queryForm.created_end) {
        created_end = EndTime(this.queryForm.created_end);
      }

      return {
        ...this.queryForm,
        created_start,
        created_end,
        page: this.page,
        page_size: this.pageSize,
        ...this.sortParams,
      };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const fiat_min_price = this.queryParams.fiat_min_price;
      const fiat_max_price = this.queryParams.fiat_max_price;

      if (fiat_min_price > fiat_max_price) {
        this.$message.error("法币最高价需要大于法币最低价");
        return;
      }

      const bsv_min_price = this.queryParams.bsv_min_price;
      const bsv_max_price = this.queryParams.bsv_max_price;

      //  TODO 隐藏部分
      // if (bsv_min_price > bsv_max_price) {
      //   this.$message.error("bsv最高价需要大于bsv最低价");
      //   return;
      // }

      const created_end = this.queryParams.created_end;
      const created_start = this.queryParams.created_start;

      if (created_start > created_end) {
        this.$message.error("开始时间不能大于结束时间");
        return;
      }

      try {
        const { data } = await getOnshelfOrders(this.queryParams);
        const { total = 0, items = [] } = data?.data;
        if (Array.isArray(items)) {
          this.data = [...items];
          this.total = total;
        } else {
          this.data = [];
          this.total = 0;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectionChange(val) {
      this.selectOrders = val;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
    onClear() {
      this.page = 1;
      this.pageSize = 20;
      this.queryForm = {};
      this.fetchData();
    },
    handleSortChange(e) {
      const { prop, order } = e;
      // 默认时间降序
      if (order === null) {
        this.sortParams = {
          sort_name: "created_time",
          sort_type: "desc",
        };
      } else {
        this.sortParams = {
          sort_name: prop === "fiat_amount" ? "amount" : prop,
          sort_type: tableSortOrder2SortType(order),
        };
      }
      this.fetchData();
    },
    async handleOffShelf(orders) {
      if (!Array.isArray(orders)) {
        this.$message("数据异常，请刷新页面重试");
        return;
      }
      const ids = orders.map((order) => order.id);
      if (ids.length <= 0) {
        return;
      }

      try {
        await offShelfOrders({
          order_ids: ids,
        });
        this.$message.success("下架成功");
        this.fetchData();
      } catch (error) {
        console.log(error);
        this.$message.error("下架失败");
      }
    },
    handleGoUser(record) {
      this.$router.push("/nft_management_log/asset?id=" + record.publish_ucid);
    },
    handleGoNFT(record) {
      this.$router.push("/nft_management/nft_items?id=" + record.nft_txid);
    },
  },
};
</script>

<style lang="less">
.number-input.el-input-number .el-input__inner {
  text-align: left;
}
</style>
